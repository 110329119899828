import React, { useEffect, useState } from 'react';
import axios from "axios";
import styles from './MainPageOption.module.css'
import classNames from 'classnames';

function MainPageOption({ closeOptionModal, setSelectedDate, SeletedDate, setAllDate, isAllDate }) {

    const [isPeopleSearchActive, setPeopleSearchActive] = useState(true);
    const [isDateSearchActive, setDateSearchActive] = useState(true);
    const [YearList, setYearList] = useState([])
    const [MonthList, setMonthList] = useState([])
    const [DayList, setDayList] = useState([])

    const [SelectedYear, setSelectedYear] = useState('-1');
    const [SelectedMonth, setSelectedMonth] = useState('-1');
    const [SelectedDay, setSelectedDay] = useState('-1');

    const handleClick_PeopleSearch = () => {
        setPeopleSearchActive(!isPeopleSearchActive); // 상태 토글

    };
    const handleClick_DateSearch = () => {
        setDateSearchActive(!isDateSearchActive); // 상태 토글

    };
    const handleAllDataCheckboxChange = () => {
        setAllDate(!isAllDate);  // 체크박스 상태 반전
    };
    const handleSelectYearChange = (e) => {
        setSelectedYear(e.target.value)


    };
    const handleSelectMonthChange = (e) => {
        setSelectedMonth(e.target.value)

    };
    const handleSelectDayChange = (e) => {
        setSelectedDay(e.target.value);



    };
    const query_month = () => {
        const data = { year: SelectedYear };
        console.log(data)
        axios.post('https://api.dreamicetrack.com/api/VideoSearch/list/month/', data, {
            headers: {
                Accept: "application/json",
            },
            withCredentials: true
        })
            .then((response) => {
                setMonthList(response.data.result)

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const query_day = () => {
        console.log('seletec', SelectedMonth)
        const data = { year: SelectedYear, month: SelectedMonth };
        axios.post('https://api.dreamicetrack.com/api/VideoSearch/list/day/', data, {
            headers: {
                Accept: "application/json",
            },
            withCredentials: true
        })
            .then((response) => {
                setDayList(response.data.result);


            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    useEffect(() => {
        axios.get('https://api.dreamicetrack.com/api/VideoSearch/list/year/', {
            headers: {
                Accept: "application/json",
            },
            withCredentials: true
        })
            .then((response) => {
                console.log("응답 받음 :", response.data.result)
                setYearList(response.data.result);
            })
            .catch((error) => {
                console.error('Error:', error);
            });


    }, []);
    useEffect(() => {
        if (YearList.length != 0) {

            if (YearList.includes(String(SeletedDate['year']))) {

                setSelectedYear(SeletedDate['year']);
            } else {
                setSelectedYear(YearList[0]);
            }
        } else {
            console.log("year 실행안됨")
        }
    }, [YearList]); // SelectedMonth가 변경될 때 실행
    useEffect(() => {
        if (MonthList.length != 0) {
            if (MonthList.includes(String(SeletedDate['month']))) {
                setSelectedMonth(SeletedDate['month']);
            } else {
                setSelectedMonth(MonthList[0]);
            }
        }

    }, [MonthList]); // SelectedMonth가 변경될 때 실행
    useEffect(() => {
        if (DayList.length != 0) {
            if (DayList.includes(String(SeletedDate['day']))) {
                setSelectedDay(SeletedDate['day']);
            } else {
                console.log("day", DayList[0])
                setSelectedDay(DayList[0]);
            }
        }


    }, [DayList]); // SelectedMonth가 변경될 때 실

    useEffect(() => {
        if (SelectedYear != '-1') {
            query_month();
        }
    }, [SelectedYear]); // SelectedMonth가 변경될 때 실행
    useEffect(() => {
        if (SelectedMonth != '-1') {
            query_day();
        }
    }, [SelectedMonth]); // SelectedMonth가 변경될 때 실행


    useEffect(() => {
        if (SelectedDay != '-1') {
            console.log("selected day1111111111111", SelectedDay)
            setSelectedDate({ 'year': SelectedYear, 'month': SelectedMonth, 'day': SelectedDay });
        }

    }, [SelectedDay]); // SelectedMonth가 변경될 때 실행
    return (
        <div className={styles.modalOverlay} onClick={closeOptionModal}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <label>Filter</label>
                        <div className={styles.modalCloseButton} onClick={closeOptionModal}>닫기</div>
                        </div>
                    <div className={styles.setting_container}>
                        <div className={styles.setting}>
                            <div className={styles.setting_control}>
                                <label>날짜 설정</label>
                                <div className={styles.setting_button_common} onClick={handleClick_DateSearch}>+</div>
                            </div>
                            <div className={classNames(styles.setting_datesearch,
                                {
                                    [styles.flex1]: isDateSearchActive,
                                    [styles.none]: !isDateSearchActive,
                                })}
                            >
                                <label>Year</label>
                                <label><input type="checkbox" checked={isAllDate} onChange={handleAllDataCheckboxChange} /> 모든 날짜</label>
                                {/* 연도 설정 */}
                                <div className={styles.setting_datesearch_selecte_container}>
                                    <div className={styles.setting_datesearch_selecte_container_content}>
                                        <label>연도</label>
                                        <select className={styles.setting_selector_year} value={SelectedYear} onChange={handleSelectYearChange} disabled={isAllDate}>
                                            {YearList.map((year, index) => (
                                                <option value={year}>{year}</option>

                                            ))}
                                        </select>
                                    </div>
                                    <br />
                                    {/* 월 설정 */}
                                    <div className={styles.setting_datesearch_selecte_container_content}>
                                        <label>월</label>
                                        <select className={styles.setting_selector_year} value={SelectedMonth} onChange={handleSelectMonthChange} disabled={isAllDate}>
                                            {MonthList.map((month, index) => (
                                                <option value={month}>{month}</option>

                                            ))}
                                        </select>
                                    </div>
                                    <br />
                                    {/* 일 설정 */}
                                    <div className={styles.setting_datesearch_selecte_container_content}>
                                        <label>일</label>
                                        <select className={styles.setting_selector_year} value={SelectedDay} onChange={handleSelectDayChange} disabled={isAllDate}>
                                            {DayList.map((day, index) => (
                                                <option value={day}>{day}</option>

                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={styles.setting}>
                            <div className={styles.setting_control}>
                                <label>인물 검색</label>
                                <div className={styles.setting_button_common} onClick={handleClick_PeopleSearch}>+</div>
                            </div>
                            <div className={classNames(styles.setting_peoplesearch,
                                {
                                    [styles.flex1]: isPeopleSearchActive,
                                    [styles.none]: !isPeopleSearchActive,
                                })
                            }><div>dwe</div></div>
                        </div>

                    </div>
                </div>
            </div >
        </div>
    )
}

export default MainPageOption;
