// src/components/VideoList.js
import React, { useEffect, useState, useRef } from 'react';



import styles from './MainPage.module.css'; // CSS 모듈 임포트

import VideoPage from './VideoPage';
function VideoList() {


  const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);

  const handleOptionIconClick = () => {
    window.history.pushState({ OptionmodalOpen: true }, "");

    setIsOptionModalOpen(true); // 모달 열기

  };



  return (
    <div className={styles.div_body}>
      <div className={styles.div_body_background}></div>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerOptionContainer}>
          <div className={styles.headerOptionContainer_option}>Video</div>
          <div className={styles.headerOptionContainer_option}>Record</div>
          </div>
          <i className={`fas fa-bars ${styles.hamburgericon}`} onClick={() => handleOptionIconClick()}></i>
          </div>
          <VideoPage isOptionModalOpen={isOptionModalOpen} setIsOptionModalOpen={setIsOptionModalOpen}></VideoPage>
      </div>
      </div>

    
  );
}

export default VideoList;
