
import React from 'react';
import MainPage from './components/MainPage/MainPage';
import VideoUpload from './components/Upload';
import VideoUpload_T from './components/Uplaod_T';
import CreateAccount from './components/CreateAccount';
import Login from './components/Login';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
  return (
    <Router>
        <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/upload" element={<VideoUpload />} />
        <Route path="/uploadt" element={<VideoUpload_T />} />
        <Route path="/signup" element={<CreateAccount />} />
        <Route path="/login" element={<Login />} />
        </Routes>

    </Router>
  );
}

export default App;
