import React, { useState } from "react";
import axios from "axios";
import styles from './Login.module.css'; // CSS 모듈 임포트

function getCsrfToken() {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'csrftoken') {
            return value;
        }
    }
    return null;
}


function Login() {

    const [id, setID] = useState("");
    const [pw, setPW] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false); // 전송 상태 추적



    const handleIDChange = (e) => {
        setID(e.target.value);
    };
    const handlePWChange = (e) => {
        setPW(e.target.value);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) return; // 이미 전송 중이라면 실행하지 않음
        setIsSubmitting(true); // 전송 상태 시작

        const formData = new FormData();
        formData.append("id", id);
        formData.append('pw', pw);


        try {


            const response = await axios.post("https://api.dreamicetrack.com/login/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            });



            if (response.data.status === "success") {
                alert(response.data.message); // 성공 메시지 표시

                window.location.href = '/'; // 리다이렉션
            } else {
                window.location.reload(); // 새로고침
            }
        } catch (error) {
            alert("로그인 실패!")
        };
    }
    return (

        <div className={styles.container}>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="user_id"
                    value={id}
                    onChange={handleIDChange}
                    placeholder="ID"
                /><br /><br />
                <input
                    type="password"
                    name="user_pw"
                    value={pw}
                    onChange={handlePWChange}
                    placeholder="PW"
                /><br /><br />
                <input type="submit" value="로그인"/>
            </form>
            <div id="response"></div>
        </div>


    );

}

export default Login;
