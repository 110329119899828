// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.CreateAccount_container__pVCIA {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #191919;
    

}
.CreateAccount_content_button__6UMQ4{
    display: flex;
    width: 100%;

    justify-content: right;
}
.CreateAccount_content_button__6UMQ4 button{

}
`, "",{"version":3,"sources":["webpack://./src/components/CreateAccount.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,yBAAyB;;;AAG7B;AACA;IACI,aAAa;IACb,WAAW;;IAEX,sBAAsB;AAC1B;AACA;;AAEA","sourcesContent":["\r\n.container {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n    background-color: #191919;\r\n    \r\n\r\n}\r\n.content_button{\r\n    display: flex;\r\n    width: 100%;\r\n\r\n    justify-content: right;\r\n}\r\n.content_button button{\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CreateAccount_container__pVCIA`,
	"content_button": `CreateAccount_content_button__6UMQ4`
};
export default ___CSS_LOADER_EXPORT___;
