
import React, { useEffect, useState, useRef } from 'react';
import VideoModalPage from '../Modal/VideoModalPage';
import MainPageOption from './MainPageOption';
import { useNavigate } from "react-router-dom";
import styles from './VideoPage.module.css'; // CSS 모듈 임포트
import axios from 'axios';


function VideoPage({isOptionModalOpen, setIsOptionModalOpen}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isModalOpenRef = useRef(isModalOpen); // 상태를 참조하기 위한 ref
    const isOptionModalOpenRef = useRef(isOptionModalOpen); // 상태를 참조하기 위한 ref
    const [selectedVideo, setSelectedVideo] = useState('');
    const [videos, setVideos] = useState({});  // 비디오 데이터를 날짜별로 그룹화된 형태로 저장
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // useNavigate를 호출하여 navigate 변수 설정
    const [SeletedDate,setSelectedDate] = useState({year : '', month : '' ,day : ''});
    const [isAllDate,setAllDate] = useState(true)
    const handleThumbnailClick = (videoUrl) => {
      window.history.pushState({ modalOpen: true }, "");
  
      setSelectedVideo(videoUrl);
      setIsModalOpen(true); // 모달 열기
  
    };

    const closeModal = () => {

        setIsModalOpen(false); // 모달 닫기
        setSelectedVideo(''); // 선택한 비디오 초기화
        if (window.history.state && window.history.state.modalOpen) {
          window.history.back(); // 모달 상태를 히스토리에서 제거
        }
      };
      const closeOptionModal = () => {

        setIsOptionModalOpen(false); // 모달 닫기
    
        if (window.history.state && window.history.state.OptionmodalOpen) {
          window.history.back(); // 모달 상태를 히스토리에서 제거
        }
      };
  
    const simulateBackButton = () => {
      window.history.back(); // 히스토리에서 뒤로가기 수행
    };
    useEffect(() => {
      isModalOpenRef.current = isModalOpen;
    }, [isModalOpen]);
  
    useEffect(() => {
      isOptionModalOpenRef.current = isOptionModalOpen;
    }, [isOptionModalOpen]);
    // 컴포넌트가 처음 렌더링될 때 동영상 목록을 가져옴
    useEffect(() => {
      axios
        .post("https://api.dreamicetrack.com/api/YMD_videos/", {SeletedDate, isAllDate},{
          headers: {
            Accept: "application/json",
          },
          withCredentials: true
        })
        .then((response) => {
          setVideos(response.data.videos);
          setLoading(false);

        })
        .catch((error) => {
  
          if (error.response && error.response.status === 401) {
  
            navigate("/login"); // navigate를 사용하여 로그인 페이지로 리다이렉트
          } else {
  
          }
          setLoading(false);
        });
    }, [SeletedDate,isAllDate]);
  
    useEffect(() => {
      axios
        .post("https://api.dreamicetrack.com/api/YMD_videos/", {SeletedDate, isAllDate},{
          headers: {
            Accept: "application/json",
          },
          withCredentials: true
        })
        .then((response) => {
          setVideos(response.data.videos);
          setLoading(false);
        })
        .catch((error) => {
  
          if (error.response && error.response.status === 401) {
  
            navigate("/login"); // navigate를 사용하여 로그인 페이지로 리다이렉트
          } else {
  
          }
          setLoading(false);
        });
    }, [navigate]);
    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === "Backspace" || event.key === "ArrowLeft") {
          // 뒤로가기 동작을 수행
          window.history.back();
        }
      };
  
      // 키 이벤트 리스너 추가
      window.addEventListener("keydown", handleKeyDown);
  
      // 컴포넌트 언마운트 시 리스너 제거
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, []);
  
    useEffect(() => {
      // 뒤로가기 이벤트 핸들러
      const handlePopState = (event) => {
        console.log("ewe", isModalOpenRef.current)
        if (isModalOpenRef.current) {
          closeModal()
        }
        if (isOptionModalOpenRef.current) {
          closeOptionModal()
        }
      };
  
      // 이벤트 리스너 추가
      window.addEventListener('popstate', handlePopState);
  
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, []);
  
    if (loading) {
      return <p>Loading videos...</p>;  // 로딩 중 표시
    }

    return (
        <div>
        {Object.keys(videos).sort((a, b) => b - a).map((year) => (
            <div key={year} className={styles.yearGroup}>
              {/* 월별로 그룹화된 비디오 표시 */}
              {Object.keys(videos[year]).sort((a, b) => b - a).map((month) => (
                <span key={month} className={styles.monthGroup}>
                  {/* 일별로 그룹화된 비디오 표시 */}
                  {Object.keys(videos[year][month]).sort((a, b) => b - a).map((day) => (
                    <span key={day} className={styles.dayGroup}>
                      <h2 className={styles.date}>{year}.{month}.{day}</h2>
                      {/* 해당 일에 속하는 모든 비디오 썸네일 표시 */}
                      <div className={styles.gridcontainer}>
                        {videos[year][month][day].map((video, index) => (
                          <div key={index} className={styles.videoItem}>
                            <img
                              src={'https://api.dreamicetrack.com' + video + '/thumbnail.png'} // 비디오 URL을 썸네일 이미지 URL로 사용
                              className={styles.thumbnail}
                              onClick={() => handleThumbnailClick(video)}  // 썸네일 클릭 시 비디오 플레이어 열기
                            />
                          </div>
                        ))}
                      </div>
                    </span>
                  ))}
                </span>
              ))}
            </div>
  
          ))}
          <div></div>
          {isModalOpen && (

                <VideoModalPage closeModal={closeModal} selectvideo={selectedVideo} />

          )}
          {isOptionModalOpen && (
  
                <MainPageOption closeOptionModal={closeOptionModal} setSelectedDate={setSelectedDate} SeletedDate={SeletedDate} setAllDate={setAllDate} isAllDate={isAllDate} />
  
          )}
          </div>
    );
}
export default VideoPage;