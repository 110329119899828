import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function VideoUpload() {
    const [files, setFiles] = useState([]);
    const [title, setTitle] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); // 전송 상태 추적


    const handleFileChange = (e) => {
        const files = e.target.files; // 선택한 파일들이 들어있는 배열
        setFiles(Array.from(files)); // 파일 배열을 상태로 설정
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) return; // 이미 전송 중이라면 실행하지 않음
        setIsSubmitting(true); // 전송 상태 시작



        // 다중 파일을 FormData에 추가


        try {
            for (let file of files) {

                const formData = new FormData();
                formData.append("title", title);
                formData.append("file", file);  // 'files'는 Django에서 처리할 파일 필드 이름
                const response = await axios.post("https://api.dreamicetrack.com/upload/", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    withCredentials: true,
                }).catch((error) => {
                    console.error('Error 발생:', error);
                });
                console.log('업로드성공');
                console.log(response.data);
                
            }
            window.location.reload(); // 성공 시 새로고침
            alert("전부다 업로드 성공");
        } catch (error) {
            console.error("Error uploading video", error);
        } finally {
            setIsSubmitting(false); // 전송 상태 종료
        }



    };

    return (
        <div>
            <h1>Upload Video</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={handleTitleChange}
                        required
                        disabled={isSubmitting} // 전송 중이면 비활성화
                    />
                </div>
                <div>
                    <label htmlFor="file">Video File:</label>
                    <input
                        type="file"
                        id="file"
                        accept="video/*"
                        onChange={handleFileChange}
                        multiple
                        required
                        disabled={isSubmitting} // 전송 중이면 비활성화
                    />
                </div>
                <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Uploading..." : "Upload"}
                </button>
            </form>
        </div>
    );
}

export default VideoUpload;
