import React, { useState, useEffect, useRef } from 'react';
import styles from './VideoControls.module.css'; // CSS 모듈 임포트
function VideoPlayer_Controls({ controlsProps})
{
    const {
        hlsInstance,
        isFullscreen,
        selectedQuality,
        setSelectedQuality,
        isPlaying,
        progress,
        qualities,
        handleSeek,
        togglePlayPause,
        toggleFullscreen,

      } = controlsProps
    const handleQualityChange = (quality) => {
        if (hlsInstance) {
          hlsInstance.currentLevel = quality.index;
          setSelectedQuality(quality);
        }
      };

    return(

        <div className={styles.controls_show}>
        <div className={`${styles.controls_layout} ${isFullscreen ? styles.controls_layout_full : styles.controls_layout}`}>
          {/* 재생/일시정지 버튼 */}
          <div className={styles.controls_layout_item}>
            <div className={styles.controlButtons}>
              <button onClick={togglePlayPause}>
                {isPlaying ? '⏸' : '▶'}
              </button>
            </div>
          </div>
          <div className={styles.controls_layout_item}>
            <input
              className={styles.progressBar}
              type="range"
              min="0"
              max="100"
              value={progress}
              onChange={handleSeek} // 사용자가 재생바를 조작할 때
            />
          </div>
          <div className={styles.controls_layout_item}>
            <div className={styles.subcontainer}>
              <div className={styles.subcontainer_item}>
                {qualities.length > 0 && (
                  <div className={styles.dropdownContainer}>
                    <select
                      className={styles.dropdown}
                      value={selectedQuality ? selectedQuality.quality : ''}
                      onChange={(e) => {
                        const quality = qualities.find(
                          (q) => q.quality === e.target.value
                        );
                        handleQualityChange(quality);
                      }}
                    >
                      {qualities.map((quality) => (
                        <option key={quality.quality} value={quality.quality}>
                          {quality.quality}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div className={styles.controls_layout_item}>
                <button className={styles.FullScreenButton} onClick={toggleFullscreen}>Full</button>
              </div>

            </div>
          </div>
        </div>

      </div>
    );
}
export default VideoPlayer_Controls;
