import React, { useEffect, useState } from 'react';
import styles from './VideoModalPage.module.css';
import VideoPlayer from '../VideoPlayer/VideoPlayer'
import classNames from 'classnames';
import axios from 'axios';
import VideoDownloadModal from './VideoDownloadModal';
function VideoModalPage({ closeModal,selectvideo }) {
  const [RecentuploadvideoisActive, setRecentuploadvideoActive] = useState(true);
  const [RecentViewuploadvideoisActive, setRecentViewuploadvideoActive] = useState(true);
  const [FileName,setFileName] = useState("")
  const [isVideoDownloadModal,setisVideoDownloadModal] = useState(false)
  const [UUID,setUUID] = useState("")

  const handleClick_Recentuploadvideo = () => {
    setRecentuploadvideoActive(!RecentuploadvideoisActive); // 상태 토글

  };
  const handleClick_Viewuploadvideo = () => {
    setRecentViewuploadvideoActive(!RecentViewuploadvideoisActive); // 상태 토글

  };

  useEffect(() => {
    const fileName = selectvideo.split('/').pop();
    setUUID(fileName);
    const data = { value: fileName };
    
    axios.post('https://api.dreamicetrack.com/api/RequestFileName/', data ,{
      headers: {
          Accept: "application/json", 
      },  withCredentials: true})
      .then((response) => {
        setFileName(response.data.result)
      })
      .catch((error) => {
        console.error('Error:', error);
      });


  }, []);

  const closeVideoDownloadModal = () => {

    setisVideoDownloadModal(false); // 모달 닫기
    if (window.history.state && window.history.state.modalOpen) {
      window.history.back(); // 모달 상태를 히스토리에서 제거
    }
  };
  const DownloadButtonClick = () =>{
    setisVideoDownloadModal(true);
  };
  
  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
    <div className={styles.container} >
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        {/* 왼쪽 요소*/ }
        <div className={styles.left_content}>
        {/* 모바일 전용 액션바 시작*/}
        <div className={styles.actionbar_container}>
          <div className={styles.actionbar_content}>
            <div className={styles.actionbar_back} onClick={closeVideoDownloadModal}>←</div>
           <i className={`fas fa-download ${styles.actionbar_downloadbutton}`} onClick={DownloadButtonClick}></i>
              {isVideoDownloadModal && UUID&&(<VideoDownloadModal video_uuid={UUID} closeModal={closeModal}></VideoDownloadModal>)}
          </div>
        </div>
        {/* 모바일 전용 액션바 종료 */}
        <div className={styles.videocontainer}>
        <VideoPlayer masterPlaylistUrl={selectvideo}></VideoPlayer>
        </div>
        <div className={styles.info}>{FileName}</div>
        <br/>
          <div className={styles.comment}>
            <div className={styles.comment_intro}>댓글</div>
            <div></div>
          </div>
        </div>
        {/* 오른쪽 요소*/ }
        <div className={styles.right_content}>
          <div className={styles.right_content_container}>
            <div className={classNames(styles.right_content_container_uploadvideo,
              {
                [styles.flex_1]: RecentuploadvideoisActive,

              })}>
              <div className={styles.right_content_container_uploadvideo_control}>
                <label>최근 시청한 영상</label>
                <div className={styles.right_content_container_uploadvideo_control_button} onClick={handleClick_Recentuploadvideo}>+</div>
              </div>
              <div className={`${styles.right_content_container_uploadvideo_videolist} ${RecentuploadvideoisActive ? styles.right_content_container_uploadvideo_videolist : styles.none}`}
              ></div>
            </div>
            <br/>
            <div className={classNames(styles.right_content_container_uploadvideo,
              {
                [styles.flex_1]: RecentViewuploadvideoisActive,

              })}>
              <div className={styles.right_content_container_uploadvideo_control}>
                <label>최근 업로드한 영상</label>
                <div className={styles.right_content_container_uploadvideo_control_button} onClick={handleClick_Viewuploadvideo}>+</div>
              </div>
              <div className={`${styles.right_content_container_ruploadvideo_videolist} ${RecentViewuploadvideoisActive ? styles.right_content_container_uploadvideo_videolist : styles.none}`}
              ></div>

              <div className={styles.right_content_container_viewvideo_videolist}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

  );
}

export default VideoModalPage;
