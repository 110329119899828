// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Login_container__2pcnf {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #191919;
    

}

form {
    border-radius: 5px;
    background-color: white;
    padding: 10px;
}

input[type=submit] {
    width: 100%;
    background-color: #375dbb;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    }

input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid #375dbb;
}
input[type=password], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid #375dbb;
}`, "",{"version":3,"sources":["webpack://./src/components/Login.module.css"],"names":[],"mappings":";;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,yBAAyB;;;AAG7B;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,uEAAuE;IACvE;;AAEJ;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,gCAAgC;AACpC;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,gCAAgC;AACpC","sourcesContent":["\r\n\r\n.container {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n    background-color: #191919;\r\n    \r\n\r\n}\r\n\r\nform {\r\n    border-radius: 5px;\r\n    background-color: white;\r\n    padding: 10px;\r\n}\r\n\r\ninput[type=submit] {\r\n    width: 100%;\r\n    background-color: #375dbb;\r\n    color: white;\r\n    padding: 14px 20px;\r\n    margin: 8px 0;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);\r\n    }\r\n\r\ninput[type=text], select {\r\n    width: 100%;\r\n    padding: 12px 20px;\r\n    margin: 8px 0;\r\n    display: inline-block;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    box-sizing: border-box;\r\n    border: none;\r\n    border-bottom: 2px solid #375dbb;\r\n}\r\ninput[type=password], select {\r\n    width: 100%;\r\n    padding: 12px 20px;\r\n    margin: 8px 0;\r\n    display: inline-block;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    box-sizing: border-box;\r\n    border: none;\r\n    border-bottom: 2px solid #375dbb;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Login_container__2pcnf`
};
export default ___CSS_LOADER_EXPORT___;
