import React, { useState } from "react";
import axios from "axios";
import styles from './CreateAccount.module.css';
function CreateAccount() {

    const [id, setID] = useState("");
    const [pw, setPW] = useState("");
    const [name, setNAME] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); // 전송 상태 추적



    const handleIDChange = (e) => {
        setID(e.target.value);
    };
    const handlePWChange = (e) => {
        setPW(e.target.value);
    };
    const handleNameChange = (e) => {
        setNAME(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) return; // 이미 전송 중이라면 실행하지 않음
        setIsSubmitting(true); // 전송 상태 시작

        const formData = new FormData();
        formData.append("id", id);
        formData.append('pw', pw);
        formData.append('name', name);


        try {
            const response = await axios.post("https://api.dreamicetrack.com/signup/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });



            if (response.data.status === "success") {
                alert(response.data.message); // 성공 메시지 표시

                    window.location.href = '/'; // 리다이렉션
                } else {
                    window.location.reload(); // 새로고침
                }
        } catch (error) {

        };
    }

        return (
            <div className={styles.container}>
                <form onSubmit={handleSubmit}>
                <div>
                        <label htmlFor="name">name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                            required
                            disabled={isSubmitting} // 전송 중이면 비활성화
                        />
                    </div>
                    <div>
                        <label htmlFor="id">id:</label>
                        <input
                            type="text"
                            id="id"
                            value={id}
                            onChange={handleIDChange}
                            required
                            disabled={isSubmitting} // 전송 중이면 비활성화
                        />
                    </div>

                    <div>
                        <label htmlFor="pw">pw:</label>
                        <input
                            type="text"
                            id="pw"
                            value={pw}
                            onChange={handlePWChange}
                            required
                            disabled={isSubmitting}
                        />
                    </div>
                    <div className={styles.content_button}>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Sign Up..." : "Sign Up"}
                    </button>
                    </div>
                </form>
            </div>
        );
    
}

    export default CreateAccount;
