import React, { useState, useEffect, useRef } from 'react';
import Hls from 'hls.js';
import styles from './VideoPlayer.module.css'; // CSS 모듈 임포트
import VideoPlayer_Controls from './VideoControls';
import VideoDownloadModal from './VideoDownloadModal';
function VideoPlayer({ masterPlaylistUrl }) {
  const videoRef = useRef(null);
  const containerRef = useRef(null); // 컨테이너 참조
  const [qualities, setQualities] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState(null);
  const [hlsInstance, setHlsInstance] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false); // Play/Pause 상태 관리
  const [progress, setProgress] = useState(0); // 재생 위치 (백분율)
  const [duration, setDuration] = useState(0); // 전체 동영상 길이
  const [isFullscreen, setIsFullscreen] = useState(false); // 전체화면 상태 관리
  const [isVisible, setIsVisible] = useState(true); // 처음에는 보이게 설정
  const hideControlsTimer = useRef(null); // 타이머 참조
  const [isVideoDownloadModalOpen, setIsVideoDownloadModalOpen] = useState(false);
  const isVideoDownloadModalOpenRef = useRef(isVideoDownloadModalOpen); // 상태를 참조하기 위한 ref
  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();

      hls.loadSource('https://api.dreamicetrack.com' + masterPlaylistUrl + '/master.m3u8');
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        const availableQualities = data.levels.map((level, index) => ({
          quality: `${level.height}p`,
          index,
          height: level.height,
        }));
        setQualities(availableQualities);
        setSelectedQuality(availableQualities[0]);
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error('HLS.js Error:', data);
      });

      setHlsInstance(hls);
    } else {
      console.error('HLS.js is not supported in this browser.');
    }

    return () => {
      if (hlsInstance) {
        hlsInstance.destroy();
      }
    };
  }, [masterPlaylistUrl]);



  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying); // 상태 토글
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const totalDuration = videoRef.current.duration;
      if(!Number.isNaN(totalDuration)){
      setProgress((currentTime / totalDuration) * 100); // 진행률을 백분율로
      setDuration(totalDuration);
      }
    }
  };

  const handleSeek = (e) => {
    const newTime = (e.target.value / 100) * duration;
    videoRef.current.currentTime = newTime; // 동영상의 현재 시간을 업데이트
    //setProgress(e.target.value);
  };
  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);

      // 명시적으로 window.screen 사용
      if (window.screen.orientation?.unlock) {
        window.screen.orientation.unlock();
      }
    } else if (containerRef.current) {
      containerRef.current.requestFullscreen();
      setIsFullscreen(true);

      // 명시적으로 window.screen 사용
      if (window.screen.orientation?.lock) {
        window.screen.orientation.lock('landscape').catch((err) => {
          console.warn('Screen orientation lock failed:', err);
        });
      }
    }
  };

  // control box ---------------------------------------
  const handleMouseMove = () => {
    setIsVisible(true); // 마우스가 움직이면 컨트롤 표시
    clearTimeout(hideControlsTimer.current); // 기존 타이머 초기화
    hideControlsTimer.current = setTimeout(() => {
      setIsVisible(false); // 3초 후 컨트롤 숨김
    }, 3000);
  };
  const handleMouseEnter = () => {
    setIsVisible(true); // Hover 시 컨트롤 표시
    clearTimeout(window.hideControlsTimer); // 기존 타이머 취소
  };

  const handleMouseLeave = () => {
    window.hideControlsTimer = setTimeout(() => {
      setIsVisible(false); // 일정 시간 후 컨트롤 숨김
    }, 3000); // 3초 후 숨김
  };
  const closeModal = () => {

    setIsVideoDownloadModalOpen(false); // 모달 닫기화
    if (window.history.state && window.history.state.modalOpen) {
      window.history.back(); // 모달 상태를 히스토리에서 제거
    }
  };

  useEffect(() => {
    // 컴포넌트가 언마운트될 때 타이머 제거
    return () => clearTimeout(window.hideControlsTimer);
  }, []);
  useEffect(() => {
    hideControlsTimer.current = setTimeout(() => {
      setIsVisible(false); // 3초 뒤에 컨트롤 숨김
    }, 3000);

    return () => clearTimeout(hideControlsTimer.current); // 컴포넌트 언마운트 시 타이머 제거
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Backspace" || event.key === "ArrowLeft") {
        // 뒤로가기 동작을 수행
        window.history.back();
      }
    };

    // 키 이벤트 리스너 추가
    window.addEventListener("keydown", handleKeyDown);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    // 뒤로가기 이벤트 핸들러
    const handlePopState = (event) => {
      if (isVideoDownloadModalOpenRef.current) {
        closeModal()
      }

    };

    // 이벤트 리스너 추가
    window.addEventListener('popstate', handlePopState);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const controlsProps = {
    hlsInstance,
    isFullscreen,
    selectedQuality,
    setSelectedQuality,
    isPlaying,
    progress,
    qualities,
    handleSeek,
    togglePlayPause,
    toggleFullscreen,

  }

  return (
    <div
      className={styles.videoContainer} ref={containerRef}
    >
      {/* 동영상 플레이어 */}
      <video
        className={styles.video}
        ref={videoRef}
        controls={false}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => setDuration(videoRef.current.duration)} // 동영상 길이 설정
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        onDoubleClick={togglePlayPause}
      >
        Your browser does not support the video tag.
      </video>
      {isVisible&&(
      <VideoPlayer_Controls controlsProps={controlsProps}></VideoPlayer_Controls>
    )}
    </div>

  );
}

export default VideoPlayer;
