import React, { useState, useEffect, useRef } from 'react';
import Hls from 'hls.js';
import styles from './VideoDownloadModal.module.css'; // CSS 모듈 임포트
import axios from "axios";
function VideoDownloadModal({ video_uuid, closeModal }) {
  const videoRef = useRef(null);
  const containerRef = useRef(null); // 컨테이너 참조
  const [qualities, setQualities] = useState([]);
  const [selectedOption, setSelectedOption] = useState(""); // 선택된 옵션 상태 관리
  const [QList, setQList] = useState([]); // 선택된 옵션 상태 관리


  useEffect(() => {

    const data = { 'value': video_uuid }
    axios.post('https://api.dreamicetrack.com/api/VideoQualityList/', data, {
      headers: {
        Accept: "application/json",
      }, withCredentials: true
    })
      .then((response) => {
        setQList(response.data.list)
      })
      .catch((error) => {
        console.error('Error:', error);
      });


    console.log("파일 UUID : ", video_uuid);
  }, [])

  const handleChange = (event) => {
    const data = { 'value': video_uuid }
    setSelectedOption(event.target.value); // 선택된 값을 상태에 저장
  };
  const VideoDownload = (event) => {
    const data = { 'uuid': video_uuid, 'option': selectedOption }


    axios.post('https://api.dreamicetrack.com/api/VideoQDownLoad/', data, {
      responseType: 'blob',
      withCredentials: true
    }).then((response) => {

      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'downloaded_file';
    // filename*=UTF-8'' 형식 처리
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename\*=(?:UTF-8'')?(.+)/);
      if (fileNameMatch) {
        fileName = decodeURIComponent(fileNameMatch[1]); // URL 디코딩
      } else if (contentDisposition.includes('filename=')) {
        // filename= 형식도 처리 (fallback)
        const fileNameFallbackMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameFallbackMatch) {
          fileName = fileNameFallbackMatch[1];
        }
      }
    }
      // Content-Disposition에서 파일 이름 추출
      if (contentDisposition && contentDisposition.includes('filename=')) {
        console.log("Con:",contentDisposition);
        fileName = contentDisposition
          .split('filename=')[1]
          .split(';')[0]
          .replace(/"/g, ''); // 따옴표 제거
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // 서버에서 제공한 파일 이름으로 저장
      document.body.appendChild(link);
      link.click();
    });

  }
  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.container} >

        <div className={styles.content} onClick={(e) => e.stopPropagation()}>
          <div style={{ padding: "10px", color: "white" }}>영상 다운로드</div>
          <div className={styles.container_option}>
            {QList.map((option) => (
              <label key={option}>
                <input
                  type="radio"
                  name="quality"
                  value={option}
                  checked={selectedOption === option} // 현재 선택된 값과 비교
                  onChange={handleChange} // 값 변경 시 실행
                />
                {option}
              </label>
            ))}
          </div>
          <div className={styles.container_button}>
            <div className={styles.button} onClick={closeModal}>닫기</div>
            <div className={styles.button} onClick={VideoDownload}>다운로드</div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default VideoDownloadModal;
