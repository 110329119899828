import React, { useState, useEffect, useRef } from 'react';
import Hls from 'hls.js';
import styles from './VideoDownloadModal.module.css'; // CSS 모듈 임포트

function VideoDownloadModal({video_uuid,closeModal}) {
  const videoRef = useRef(null);
  const containerRef = useRef(null); // 컨테이너 참조
  const [qualities, setQualities] = useState([]);

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
    <div className={styles.container} >

    <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        
        </div>
      </div>

    </div>
  );
}

export default VideoDownloadModal;
